import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { spiraliImg, mediumImg } from "../styles/spirali.module.css";
import {
  subtitle,
  title3,
  parag,
  footnote,
  boldText,
  paragBold,
  faqSpacer,
  imgContainer,
  imgCaption,
  listItemExt,
  listItem,
  list,
  imgContainer2,
  warning,
  link,
} from "../styles/global.module.css";
import Video from "../components/Video";
import { Link } from "gatsby";

// markup
const FoliaPage = () => {
  return (
    <Layout pageTitle="Folia">
      <h2 className={subtitle}>Celebrating the joy and vibrancy of Carnival</h2>
      <StaticImage
        alt="Folia example image"
        src="../images/folia.png"
        className={spiraliImg}
      />

      <h3 className={title3}>Project information</h3>

      <p className={parag}>
        Folia will be launched on Alba.art on{" "}
        <span className={boldText}>Nov. 30th, 2023 at 5pm UTC</span>. The
        project page can be accessed{" "}
        <a
          href="https://www.alba.art/projects/folia"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          here.
        </a>{" "}
      </p>
      <ul className={list}>
        <li className={listItem}>Size: 256</li>
        <li className={listItem}>
          Price: <span>Ξ</span> 0.025
        </li>
      </ul>

      <h3 className={title3}>Why Folia?</h3>

      <p className={parag}>
        Folia is the Portuguese word for revelry, a joyful and noisy
        celebration. In Brazil, folia is mainly used to refer to Carnival as
        people take part in vibrant gatherings, wearing colorful costumes and
        dancing lively rhythms like Samba, Frevo, Axé Music and many others.
        This collection tries to capture that spirit with vibrant color palettes
        and shapes that give a feeling of movement. The organic patterns
        resemble the costumes and decorations associated with this festivity.
      </p>

      <h3 className={title3}>Design strategy</h3>

      <p className={parag}>
        Folia is built by sequentially dividing a base rectangle with several
        shapes. These divisions gives rise to sub-shapes that are filled with
        solid colors or patterns. The video below illustrates that process.
      </p>

      <Video
        videoSrcURL="https://player.vimeo.com/video/866497464?badge=0&autoplay=1&loop=1&title=0&byline=0&portrait=0"
        videoTitle="Folia video"
        width="560"
        height="700"
      />
      <p className={parag}>
        There can be up to three shapes sets. Some shapes are drawn through flow
        fields which use perlin noise or simpler mathematical formulas that I
        have created. Basic shapes like circles, squares and triangles are also
        used and are arranged according to circle packing, quadtree and triangle
        subdivision algorithms, respectively. The possible combinations for the
        three shapes sets are:
      </p>
      <ul className={list}>
        <li className={listItem}>Flow field and circle/mix* packing</li>
        <li className={listItem}>Two flow field sets and circle packing</li>
        <li className={listItem}>Flow field, quadtree and circle packing</li>
        <li className={listItem}>Flow field and concentric circles</li>
        <li className={listItem}>
          Concentric circles and triangletree (triangle subdivision)
        </li>
        <li className={listItem}>Rays**, quadtree and circle packing</li>
      </ul>
      <p className={footnote}>
        * Mix packing is a circle packing algorithm using circles and squares.{" "}
        <br />
        ** Rays is a set of triangles with one common vertice for all of them.
      </p>
      <p className={parag}>
        The images below show some examples how the different shapes set look
        like.
      </p>
      <div className={imgContainer2}>
        <figure>
          <StaticImage
            alt="Flow field and circle packing"
            src="../images/folia-ff-cp.png"
            className={mediumImg}
          />
          <figcaption className={imgCaption}>
            Flow field and circle packing
          </figcaption>
        </figure>
        <figure>
          <StaticImage
            alt="Two flow fields and cicle packing"
            src="../images/folia-ff-ff-cp.png"
            className={mediumImg}
          />
          <figcaption className={imgCaption}>
            Two flow fields and cicle packing
          </figcaption>
        </figure>
      </div>
      <div className={imgContainer2}>
        <figure>
          <StaticImage
            alt="Flow field, quadtree and circle packing"
            src="../images/folia-ff-qt-cp.png"
            className={mediumImg}
          />
          <figcaption className={imgCaption}>
            Flow field, quadtree and circle packing
          </figcaption>
        </figure>
        <figure>
          <StaticImage
            alt="Flow field and concentric circles"
            src="../images/folia-ff-cc.png"
            className={mediumImg}
          />
          <figcaption className={imgCaption}>
            Flow field and concentric circles
          </figcaption>
        </figure>
      </div>
      <h3 className={title3}>Patterns</h3>
      <p className={parag}>
        The following algorithms are used to build the patterns:
      </p>
      <ul>
        <li className={listItem}>Circle packing</li>
        <li className={listItem}>Poisson disk sampling</li>
        <li className={listItem}>Uniform and gaussian distribution</li>
        <li className={listItem}>Voronoi diagram</li>
        <li className={listItem}>Multi-scale truchet patterns</li>
      </ul>

      <h3 className={title3}>Palettes</h3>
      <p className={parag}>
        There are a total of 9 color palettes in this project. All of them very
        vibrant and colorful to do justice to the name of this collection.{" "}
      </p>
      <ul>
        <li className={listItem}>Whimsical</li>
        <li className={listItem}>Spring Garden</li>
        <li className={listItem}>Circus</li>
        <li className={listItem}>Sunburn</li>
        <li className={listItem}>Dreamscape</li>
        <li className={listItem}>Meadows</li>
        <li className={listItem}>Evening Bloom</li>
        <li className={listItem}>Crimson Twilight</li>
        <li className={listItem}>Ruby</li>
      </ul>

      <h3 className={title3}>Third-party code</h3>

      <p className={parag}>
        This project use the following code or idea that were not created by
        myself.
      </p>
      <ul>
        <li className={listItem}>
          {" "}
          <a
            href="http://paperjs.org/"
            target="_blank"
            rel="noopener noreferrer"
            className={link}
          >
            Paper.js
          </a>{" "}
          library (v0.12.17)
        </li>
        <li className={listItem}>
          {" "}
          <a
            href="https://gist.github.com/akella/df27506fabdb839de0857ff12f2cd7a0"
            target="_blank"
            rel="noopener noreferrer"
            className={link}
          >
            Perlin noise
          </a>{" "}
          from Yuri Artiukh, based on example code by Stefan Gustavson
        </li>
        <li className={listItem}>
          {" "}
          <a
            href="https://github.com/gorhill/Javascript-Voronoi"
            target="_blank"
            rel="noopener noreferrer"
            className={link}
          >
            Javascript Voronoi
          </a>{" "}
          from Raymond Hill, based on Steven J. Fortune's algorithm
        </li>
        <li className={listItem}>
          The multi-scale truchet patterns were based on{" "}
          <a
            href="https://archive.bridgesmathart.org/2018/bridges2018-39.html#gsc.tab=0"
            target="_blank"
            rel="noopener noreferrer"
            className={link}
          >
            this paper
          </a>{" "}
          from Christopher Carlson
        </li>
      </ul>
      <p className={`${faqSpacer}`}></p>
    </Layout>
  );
};

export default FoliaPage;
