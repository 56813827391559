// extracted by mini-css-extract-plugin
export var boldText = "global-module--boldText--PZqKi";
export var faqSpacer = "global-module--faqSpacer--4UUt3";
export var footnote = "global-module--footnote--6YBfW";
export var imgCaption = "global-module--imgCaption--c0+48";
export var imgContainer = "global-module--imgContainer--X-K7O";
export var imgContainer2 = "global-module--imgContainer2--h6-w7";
export var link = "global-module--link--CJdmv";
export var list = "global-module--list--ZgbNe";
export var listItem = "global-module--listItem--NtuVA";
export var listItemExt = "global-module--listItemExt--xN1b2";
export var parag = "global-module--parag--VKjmc";
export var paragBold = "global-module--paragBold--qk3nv";
export var subtitle = "global-module--subtitle--Ca2K2";
export var title3 = "global-module--title3--f05x4";
export var videoDiv = "global-module--videoDiv--UkLjW";
export var warning = "global-module--warning--m4bGb";