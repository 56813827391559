import * as React from "react";
import { videoDiv } from "../styles/global.module.css";

const Video = ({ videoSrcURL, videoTitle, width, height, ...props }) => (
  <div className={videoDiv}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="false"
      mozallowfullscreen="false"
      allowFullScreen
      width={width}
      height={height}
      autoPlay="true"
    />
  </div>
);
export default Video;
